/* footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

     */

.bootom{
    border-top: 1px var(--foam) solid;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
}


@media (max-width: 650px) {
    .bootom {
        margin-left: 5%;
        margin-right: 5%;
        font-size: 17.5px;
    }
}
@media (max-width: 390px) {

    .bootom {
        margin-left: 4%;
        margin-right:4%;
        font-size: 14px;
    }
}