@import url("https://fonts.googleapis.com/css?family=Manrope");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,800");
@import url("https://fonts.googleapis.com/css?family=Sora:400,700,800");
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');

.backgroundlol{
  background: rgb(2,0,36);
  background: linear-gradient(63deg, rgba(2,0,36,1) 0%, rgba(99,47,162,1) 100%);
  width: 5000px;
  height: 5000px;
}
body{
  /* --lines: #622fad;
  --texts : #c29aff;
  --back : #eae0fa; */

  /* --lines: #000000;
  --texts : #363636;
  --back : #c4c4c4; */

  /* --lines: #eb6f92;
  --texts : #ebbcba;
  --back : #1f1d2e; */

  /* --lines: #c4a7e7;
  --texts : #e0def4;
  --back : #191724; */

  
  /* background-image: linear-gradient(180deg, var(--subtle),var(--base));
  */

  --base:#191724;
  --surface:#1f1d2e;
  --overlay:#26233a;
  --muted:#6e6a86;
  --subtle:#908caa;
  --text:#e0def4;
  --love:#eb6f92;
  --gold:#f6c177;
  --rose:#ebbcba;
  --pine:#31748f;
  --foam:#9ccfd8;
  --iris:#c4a7e7;
  --low:#21202e;
  --med:#403d52;
  --high:#524f67;

  font-family: "Manrope";
  font-size: 20px;
  background: var(--surface);
  color: var(--iris);
  transition: 0.5s;
  
  
}
.App{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* min-height: -webkit-fill-available; */
}

.light{
  --base:#faf4ed;
  --surface:#fffaf3;
  --overlay:#f2e9e1;
  --muted:#9893a5;
  --subtle:#797593;
  --text:#575279;
  --love:#b4637a;
  --gold:#ea9d34;
  --rose:#d7827e;
  --pine:#286983;
  --foam:#56949f;
  --iris:#907aa9;
  --low:#f4ede8;
  --med:#dfdad9;
  --high:#cecacd;

  transition: 0.5s;
}


body::-webkit-scrollbar{
  display: none;
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
  }
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.imagecontainer{
  display: flex;
  align-items: center;
  padding: 20px;
  transition: 1s;
}


.bodylol{
  flex-grow: 1;

}
@keyframes background-pan {
  from{
    background-position: 0% center;
  }
  to{
    background-position: -200% center;
  }
}



.magic{
  
  animation: background-pan 1s linear infinite;
  background: linear-gradient(
    to right,
    var(--love),
    var(--gold),
    var(--rose),
    var(--pine),
    var(--love)
    /* var(--foam),
    var(--foam),
    var(--love),
    var(--love),
    var(--text),
    var(--text),
    var(--love),
    var(--love),
    var(--foam),
    var(--foam) */
  );

  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;

}

/* .magic2{
  
  animation: background-pan 3s linear infinite;
  background: linear-gradient(
    to right,
    var(--love),
    var(--iris),
    var(--foam),
    var(--foam),
    var(--iris),
    var(--love)
  );

  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;

} */

.hometext h1{
  
  font-family: 'Shadows Into Light', cursive;
  font-size: 50px;
  transition: 1s;
  
}

.profilelol{
  width: 250px;
  height: 250px;
  border-radius: 100%;
  border: var(--love) 4px solid;
  margin-right: 40px;
  margin-left: 40px;
  animation: morph 8s ease-in-out infinite;
  transition: 1s;
}

.containerlol{
  color: var(--text);
  margin-left: 20%;
  margin-right: 20%;

  transition: 1s;
}
.homecontent{
  display: flex;
  padding-top: 10%;
  padding-bottom: 15%;
  justify-content: space-around;
  transition: 1s;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.hometext{
  max-width: 60%;
  transition: 1s;
  font-size: 25px;
}

.PageHeader{
  font-size: 40px;
  margin-bottom: 40px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  transition: 1s;
}





/* PROJECTS PAGE CSS */

.ProjectContainer{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: var(--text);
  margin-left: 10%;
  margin-right: 10%;

  transition: 1s;
}




/* ABOUT PAGE CSS */


.aboutcontainer{
  display: flex;
  flex-direction: column;
  margin-right: 15%;
  margin-left: 15%;

  transition: 1s;
}











.headlol{
  color: var(--text);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 19%;
  margin-right: 19%;
  line-height: 76.19px;

  transition: 1s;
}
.headbuttons{
  display: flex;
}

.namelol{
  font-family: "Poppins";
  font-weight: 800;
  font-size: 2em;
  user-select: none;
  width: 272px;
  text-align: center;
  text-decoration: none
}

.material-symbols-outlined{
  font-size: 35px;
}
a{
  width: 50px;
  height: 50px;
  display: block;
  text-align: center;
}
a:link{
  color: var(--text);
}
a:visited{
  color: var(--text);
}
a:hover{
  color: var(--text);
}
a:active{
  color: var(--text);
}
.darkbutton{
  width: 50px;
  height: 50px;
  display: block;
  text-align: center;
}
span{
  text-align: center;
  user-select: none;
}


.buttonlol{

  margin: 5px;
  border: #00000000 3px solid;
  border-radius: 35%;
  width: 50px;
  height: 50px;
  user-select: none;
  transition: 1s;
}
.buttonlol:hover{
  border: var(--rose) 3px solid;
  background: #ffffff10;
  padding: 5px;
  width: 50px;
  height: 50px;
  transition: 1s;
  cursor: pointer;
  user-select: none;
}
.buttonlol:hover a{
  color: var(--rose);
  transition: 1s;
  cursor: pointer;
  
}
.buttonlol:hover label{
  color: var(--rose);
  transition: 1s;
  cursor: pointer;
  
}




.homeprojects{
  margin-left: 40px;
}
















@media(max-width: 1365px) {

  .ProjectContainer{
    align-items: center;
    flex-direction: column;
    transition: 1s;
    
  }
 



  .homecontent{
    display: flex;
    align-items: center;
    padding-top: 10%;
    padding-bottom: 20%;
    flex-direction: column;
    justify-content: center;
    transition: 1s;

  }
  .hometext{
    max-width: 80%;
    transition: 1s;
  }
  .profilelol{
    margin-right: 0px;
    margin-left: 0px;
    transition: 1s;
  }
  .imagecontainer{
    padding: 20px;
    transition: 1s;
  }
  .hometext h1{
    
    text-align: center;
    transition: 1s;
  }
  .headlol{
    
    margin-left: 10%;
    margin-right: 10%;
    transition: 1s;
    
  }
  .containerlol{
    margin-left: 10%;
    margin-right: 10%;

    transition: 1s;
  }
}

@media (max-width: 980px) {
  .ProjectContainer{
    margin-left: 0%;
    margin-right: 5%;
  }
}


@media(max-width: 750px){
  .PageHeader{
    margin-top: 20px;
    margin-bottom: 20px;
    transition: 1s;
  }
  
  .namelol{
    line-height: normal;
    margin-bottom: 5px;
    transition: 1s;
  }
  .headlol{
    display: flex;
    flex-direction: column;
    
    align-items: center;
    margin-left: 25%;
    margin-right: 25%;
    transition: 1s;
    
  }
}
@media(max-width: 630px){
  .hometext h1{
    font-size: 30px;
    transition: 1s;
  }
}

@media(max-width: 450px){

  .namelol{
    font-size: 1.5em;
  }

  
  
  .hometext p{
    font-size: 18px;
    font-weight: 500;
    transition: 1s;
  }
  .profilelol{
    width: 200px;
    height: 200px;
    margin-right: 0px;
    margin-left: 0px;
    transition: 1s;
  }
}