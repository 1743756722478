
.mylinks{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.linklol{

    flex: 1;
    font-size: 30px;
    margin-left: 50px;
    margin-right: 50px;
    

    text-decoration: none;
    outline-color: transparent;
    text-align: center;

    transition: 1s;

}

a:hover{
    font-size: 35px;
    color: var(--love);
    
    transition: 1s;
    cursor: pointer;
}
@media(max-width: 500px){

    .mylinks {
        flex-direction: column;
        text-decoration: underline;
    }
    
    .linklol:nth-child(even) {
        margin-left: 50%;
    }
    
    .linklol:nth-child(odd) {
        margin-right: auto;
        margin-left: 10%;
    }


}

