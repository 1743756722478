/* PROJECTS PAGE CSS */

.type{
  width: 60%;
}

  .Project{
    border: var(--rose) 4px solid;
    border-radius: 25px;
    
    padding: 10px;
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    
    min-width: 75%;
    
    max-height: 300px;
  
    transition: 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .Project-title{
    font-size: 30px;
    font-family: monospace;
    width: 75%;
    padding: 10px;
    color: var(--text);
    font-weight: 900;
    
    
    width: 100%;
    transition: 1s;
  }
  
  .Project-body{
    font-size: 18px;
    padding: 10px;
    color: var(--subtle);
    
    width: 75%;
    overflow:hidden;
    transition: 1s;
  }
  
  .Project-pic{
    width: 100px;
    height: 100px;
    
    margin-right:25px;
    border-radius: 50%;
    transition: 1s;
    
  }
  

  @media(max-width: 1365px) {

    .Project{
  
      width: 90%;
  
      transition: 1s;
  
    }

  }

 
  
  @media(max-width: 630px){
    .Project-pic{
    width: 75px;
    height: 75px;
    
    margin-right:20px;
    border-radius: 50%;
    transition: 1s;
    }

  }




@media(max-width: 750px){

    
  
    .Project-title{
      font-size: 23px;
      transition: 1s;
    }
  
    .Project-body{
      font-size: 14px;
      transition: 1s;
    }
}

@media(max-width: 450px){
  .Project-title{
    font-size: 18px;
    transition: 1s;
  }

  .Project-body{
    font-size: 12px;
    transition: 1s;
  }
  
}