@import url("https://fonts.googleapis.com/css?family=Manrope");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,800");
@import url("https://fonts.googleapis.com/css?family=Sora:400,700,800");
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');

.aboutclass{
    display: flex;
    justify-content: space-between;
    transition: 1s;
}

.abouttext{
    flex-wrap: wrap;
    color: var(--text);
    
    transition: 1s;
    justify-items: center;
}

